import React from "react";
import Banner from "../components/Banner";
import Comentarios from "../components/Comentarios";
import ComentariosEscritorio from "../components/ComentariosEscritorio";
import Locales from "../components/Locales";
import RedesSociales from "../components/RedesSociales";
import Tentate from "../components/Tentate";

export default function Home() {
  return (
    <div>
      <Banner />
      <Tentate />
      <Locales />
      <div className="d-none d-lg-block">
        {" "}
        <ComentariosEscritorio />{" "}
      </div>
      <div className="d-lg-none d-block">
        {" "}
        <Comentarios />
      </div>

      <RedesSociales />
    </div>
  );
}
