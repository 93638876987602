import React from 'react'
import RedesSociales from '../components/RedesSociales'
import Sumate from '../components/Sumate'

export default function TrabajaConNosotros() {
  return (
    <div>
      <Sumate />
      <RedesSociales />
    </div>
  )
}
