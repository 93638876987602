import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import QuienesSomos from "./Pages/QuienesSomos";
import TrabajaConNosotros from "./Pages/TrabajaConNosotros";
import Franquicia from "./Pages/Franquicia";
import NavbarReact from "./components/NavbarReact";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import NavEscritorio from "./components/NavEscritorio";
import Eventos from "./Pages/Eventos";

function App() {
    return (
        <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
            <HashRouter>
                <ScrollToTop />
                <div className="d-none d-lg-block">
                    <NavEscritorio />
                </div>
                <div className="d-lg-none d-block">
                    <NavbarReact />
                </div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Quienessomos" element={<QuienesSomos />} />
                    <Route path="/Eventos" element={<Eventos />} />
                    <Route
                        path="/Trabajaconnosotros"
                        element={<TrabajaConNosotros />}
                    />
                    <Route path="/Franquicia" element={<Franquicia />} />
                </Routes>
                <Footer />
            </HashRouter>
        </div>
    );
}

export default App;
