import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card } from "react-bootstrap";

import matambre from "../assets/img/matambre.jpg";
import provoleta from "../assets/img/provoleta.jpg";
import choripan from "../assets/img/choripan.jpg";
import burguer from "../assets/img/burguers.jpg";
import plato from "../assets/img/alplato.jpg";
import lomo from "../assets/img/lomo.jpg";

function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
        width,
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default function CardsTentate() {
    const { width } = useWindowDimensions();

    return (
        <Swiper slidesPerView={width <= 500 ? 2.5 : 5} className=" pb-4 ms-3">
            <SwiperSlide className="d-flex flex-column ">
                <Card className="card-tentate mx-2">
                    <Card.Img className="card-tentate-img" src={matambre} alt="tentate"  />
                </Card>
                <p className="tamaño-chico mt-1 pb-2 ms-2">Matambre</p>
                
            </SwiperSlide>
            <SwiperSlide className="d-flex flex-column ">
                <Card className="card-tentate mx-2">
                    <Card.Img className="card-tentate-img" src={lomo} alt="tentate"  />
                </Card>
                <p className="tamaño-chico mt-1 pb-2 ms-2">Lomo</p>
            </SwiperSlide>
            <SwiperSlide className="d-flex flex-column ">
                <Card className="card-tentate mx-2">
                    <Card.Img className="card-tentate-img" src={provoleta} alt="tentate"  />
                </Card>
                <p className="tamaño-chico mt-1 pb-2 ms-2">Provoleta</p>
            </SwiperSlide>
            <SwiperSlide className="d-flex flex-column ">
                <Card className="card-tentate mx-2">
                    <Card.Img className="card-tentate-img" src={choripan} alt="tentate"  />
                </Card>
                <p className="tamaño-chico mt-1 pb-2 ms-2">Choripan</p>
            </SwiperSlide>
            <SwiperSlide className="d-flex flex-column ">
                <Card className="card-tentate mx-2">
                    <Card.Img className="card-tentate-img" src={plato} alt="tentate"  />
                </Card>
                <p className="tamaño-chico mt-1 pb-2 ms-2 ">Al plato</p>
            </SwiperSlide>
        </Swiper>
    );
}
