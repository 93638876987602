import React from "react";
import { Nav, Navbar} from "react-bootstrap";
import { Link } from "react-router-dom";


export default function NavbarReact() {
  return (
    <>
      <Navbar
        className="position-absolute "
        fixed="top"
        expand="lg"
        variant="dark"
      >
        <div className="container-fluid pe-0 ms-auto d-flex justify-content-end">
          <Navbar.Toggle
            className="me-2 mt-2"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse className="ms-auto" id="basic-navbar-nav">
            <div className="navbar-celu my-2 pe-0 border-0">
              <Nav className="my-2 py-2">
              <Nav.Link
                  className="tamaño-chico text-white ms-3"
                  as={Link}
                  to="/"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  className="tamaño-chico text-white ms-3"
                  as={Link}
                  to="/Quienessomos"
                >
                  Quienes <br />
                  Somos
                </Nav.Link>
                <Nav.Link
                  className="tamaño-chico text-white ms-3"
                  as={Link}
                  to="/Eventos"
                >
                  Eventos
                </Nav.Link>
                <Nav.Link
                  className="tamaño-chico text-white ms-3"
                  as={Link}
                  to="/Trabajaconnosotros"
                >
                  Trabaja con <br />
                  nosotros
                </Nav.Link>
                <Nav.Link
                  className="tamaño-chico text-white ms-3"
                  as={Link}
                  to="/Franquicia"
                >
                  Franquicia
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
