import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NavEscritorio() {
  return (
    <>
      <Navbar
        className="position-absolute container d-flex justify-content-center  mt-3 pb-5 "
        fixed="top"
        expand="lg"
        variant="dark"
      >
        <div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="" id="basic-navbar-nav">
            <div className="navbar-fixed my-2">
              <Nav className="my-2 mx-xxl-5 mx-4 tamaño-medio">
                <Nav.Link
                  className="text-white peso-bold mx-xxl-3 mx-2 text-nowrap"
                  as={Link}
                  to="/"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  className="text-white peso-bold mx-xxl-3 mx-2 text-nowrap"
                  as={Link}
                  to="/Quienessomos"
                >
                  Quienes somos
                </Nav.Link>
                <Nav.Link
                  className="text-white peso-bold mx-xxl-3 mx-2 text-nowrap"
                  as={Link}
                  to="/Eventos"
                >
                  Eventos
                </Nav.Link>
                <Nav.Link
                  className="text-white peso-bold mx-xxl-3 mx-2 text-nowrap"
                  as={Link}
                  to="/Trabajaconnosotros"
                >
                  Trabaja con nosotros
                </Nav.Link>
                <Nav.Link
                  className="text-white peso-bold mx-xxl-3 mx-2 text-nowrap"
                  as={Link}
                  to="/Franquicia"
                >
                  Franquicia
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
