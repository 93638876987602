import { Toast, Image, Collapse } from "react-bootstrap";
import face1 from "../assets/img/sinfotocomentario.jpg";
import face2 from "../assets/img/sinfotocomentario.jpg";
import face3 from "../assets/img/sinfotocomentario.jpg";
import face4 from "../assets/img/sinfotocomentario.jpg";
import estrellas from "../assets/img/stars.png";
import estrellas4 from "../assets/img/stars4.png";
import smile from "../assets/img/smile.svg";
import triangulo from "../assets/img/triangulocomentario.svg";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

export default function ComentariosEscritorio() {

    return (
        <div>
            <div className=" container ">
                <div>
                    <div className="d-flex justify-content-center">
                        <div className="color-blanco-fondo comentario p-1 mx-2">
                            <div className="d-flex justify-content-around my-2 mx-2">
                                <p className="text-dark tamaño-grande peso-bold mb-0">
                                    Opiniones de nuestros <br /> comensales
                                </p>{" "}
                                <span>
                                    <Image
                                        className="ps-3"
                                        src={smile}
                                        fluid
                                        alt="comentarios"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Image src={triangulo} fluid alt="triangulo" />
                </div>
                <div className="my-5">
                    {/* Comentario #1 */}
                    <Swiper slidesPerView={2.5} className=" pb-4 ms-3">
                        <SwiperSlide className="d-flex flex-column ">
                            <div className="">
                                <Toast className="fondo-card">
                                    <Toast.Header className="fondo-card">
                                        <div>
                                            <Image
                                                src={face1}
                                                style={{ width: "60px" }}
                                                roundedCircle
                                                fluid
                                                alt="perfil"
                                            />
                                        </div>
                                        <div className="ms-4">
                                            <span className="text-white tamaño-chico">
                                                Luciana Andrea
                                            </span>{" "}
                                            <span className="mx-4 text-white-50 tamaño-chico">
                                                Sep/2022
                                            </span>{" "}
                                            <br />
                                            <div className="my-1">
                                                <span className="mt-2 text-white peso-bold tamaño-chico">
                                                    5
                                                </span>{" "}
                                                <span className="ms-2">
                                                    <Image
                                                        className="mb-1"
                                                        src={estrellas}
                                                        fluid
                                                        alt="estrellas"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <p className="text-white-50 tamaño-chico">
                                            Excelente el punto de la carne, el
                                            sabor, todo exquisito... abundante
                                            la porción
                                        </p>
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="d-flex flex-column ">
                            {/* Comentario #2 */}
                            <div className="">
                                <Toast className="fondo-card">
                                    <Toast.Header className="fondo-card">
                                        <div>
                                            <Image
                                                src={face2}
                                                style={{ width: "60px" }}
                                                roundedCircle
                                                fluid
                                                alt="perfil"
                                            />
                                        </div>
                                        <div className="ms-4">
                                            <span className="text-white tamaño-chico">
                                                Romina Julieta
                                            </span>{" "}
                                            <span className="mx-4 text-white-50 tamaño-chico">
                                                Sep/2022
                                            </span>{" "}
                                            <br />
                                            <div className="my-1">
                                                <span className="mt-2 text-white peso-bold tamaño-chico">
                                                    4
                                                </span>{" "}
                                                <span className="ms-2">
                                                    <Image
                                                        className="mb-1"
                                                        src={estrellas4}
                                                        fluid
                                                        alt="estrellas"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <p className="text-white-50 tamaño-chico">
                                            El sanguche de morcilla excelente,
                                            pero debería venir pisada o por lo
                                            menos cortada al medio
                                        </p>
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="d-flex flex-column ">
                            {/* Comentario #3 */}
                            <div className="">
                                <Toast className="fondo-card">
                                    <Toast.Header className="fondo-card">
                                        <div>
                                            <Image
                                                src={face3}
                                                style={{ width: "60px" }}
                                                roundedCircle
                                                fluid
                                                alt="perfil"
                                            />
                                        </div>
                                        <div className="ms-4">
                                            <span className="text-white tamaño-chico">
                                                Emanuel
                                            </span>{" "}
                                            <span className="mx-4 text-white-50 tamaño-chico">
                                                Sep/2022
                                            </span>{" "}
                                            <br />
                                            <div className="my-1">
                                                <span className="mt-2 text-white peso-bold tamaño-chico">
                                                    5
                                                </span>{" "}
                                                <span className="ms-2">
                                                    <Image
                                                        className="mb-1"
                                                        src={estrellas}
                                                        fluid
                                                        alt="estrellas"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <p className="text-white-50 tamaño-chico">
                                            Exquistos como siempre los
                                            sandwiches, lo único que nunca le
                                            ponen suficiente picante como pido,
                                            aunque es por que soy exagerado.
                                        </p>
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="d-flex flex-column ">
                            {/* Comentario #4 */}
                            <div className="">
                                <Toast className="fondo-card">
                                    <Toast.Header className="fondo-card">
                                        <div>
                                            <Image
                                                src={face4}
                                                style={{ width: "60px" }}
                                                roundedCircle
                                                fluid
                                                alt="perfil"
                                            />
                                        </div>
                                        <div className="ms-4">
                                            <span className="text-white tamaño-chico">
                                                Yani
                                            </span>{" "}
                                            <span className="mx-4 text-white-50 tamaño-chico">
                                                Jul/2022
                                            </span>{" "}
                                            <br />
                                            <div className="my-1">
                                                <span className="mt-2 text-white peso-bold tamaño-chico">
                                                    4
                                                </span>{" "}
                                                <span className="ms-2">
                                                    <Image
                                                        className="mb-1"
                                                        src={estrellas4}
                                                        fluid
                                                        alt="estrellas"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <p className="text-white-50 tamaño-chico">
                                            Como siempre, exquisito todo!!! ❣
                                        </p>
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
