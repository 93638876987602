import { Image } from "react-bootstrap";
import logoFooter from "../assets/img/Logo.png";
import { NavLink } from "react-router-dom";
import imagen1 from "../assets/img/somos1.png";

export default function Eventos() {
  return (
    <div className="container">
      <div className="d-flex justify-content-center mt-5">
        <NavLink to={"/"}>
          <Image className="mt-4 pt-5" src={logoFooter} fluid alt="logo" />
        </NavLink>
      </div>
      <div className="">
        <div>
          <div>
            <p className="mx-4 tamaño-grande text-center text-white peso-black my-4 pb-2">
              Eventos
            </p>
          </div>

          <div className="d-flex justify-content-center row">
            <div className="col-12 col-md-6">
              <Image
                className="w-100 pb-sm-5 pb-0 px-sm-0 px-5"
                src={imagen1}
                alt="First slide"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center">
              {" "}
              <p className="text-white tamaño-chico mb-0 px-5 celular-texto">
                ¡Podemos hacer de tu fiesta la más épica con nuestro foodtruck!
                La propuesta de eventos es un servicio especial ideal para que
                tus invitados se diviertan y deleiten con el sabor de la carne
                recién salida de la parrilla. Por presupuestos y reservas,
                comunícate con nosotros.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="container">
    //   <div className="d-flex justify-content-center mt-5">
    //     <NavLink to={"/"}>
    //       <Image className="mt-4 pt-5" src={logoFooter} fluid alt="logo" />
    //     </NavLink>
    //   </div>
    //   <div>
    //     <p className="mx-4 tamaño-grande text-center text-white peso-black my-4">
    //       Eventos
    //     </p>
    //   </div>
    //   <div className="carrousel-conocenos my-5">
    //     <Carousel>
    //       <Carousel.Item>
    //         <div className="d-sm-flex d-block justify-content-center">
    //           <Image
    //             className="w-100 pb-sm-5 pb-0 px-sm-0 px-5 "
    //             src={imagen1}
    //             alt="First slide"
    //           />
    //           <Carousel.Caption className="texto-qsomos position-static mx-5 d-flex align-items-center pb-5 pt-3 pt-sm-0">
    //             <p className="text-white-50 tamaño-chico mb-0">
    //
    //             </p>
    //           </Carousel.Caption>
    //         </div>
    //       </Carousel.Item>
    //     </Carousel>
    //   </div>
    // </div>
  );
}
