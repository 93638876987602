import React from "react";
import RedesSociales from '../components/RedesSociales'
import Conocenos from "../components/Conocenos";

export default function QuienesSomos() {
    return (
        <div>
            <Conocenos />
            <RedesSociales />
        </div>
    );
}
