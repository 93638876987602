import React from "react";
import { Button } from "react-bootstrap";
import logo from "../assets/img/Logo.png";
import { Image } from "react-bootstrap";
import imagen1 from "../assets/img/Burguer-banner-escritorio2.png";
import imagen2 from "../assets/img/Burguer-banner.png";
import imagen3 from "../assets/img/Burguer-banner-escritorio3.png";

export default function Banner() {
    return (
        <div className="">
            {/* Escritorio  */}
            <div className="d-none d-lg-block container banner-margen">
                <div className="d-flex justify-content-center">
                    <div className="banner-logo-escritorio">
                        <Image src={logo} alt="logo-banner" />
                    </div>
                    <div className="banner-text-escritorio container ">
                        <p className="tamaño-grande peso-black mb-0">
                            Date un gustito...
                        </p>
                        <p className="tamaño-chico">
                            Hacé tu pedido a través de Olaclick
                        </p>
                        <div>
                            <a
                                href="https://grillman.ola.click/products"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="boton-pedir-escritorio color-fondo-naranja border-0 tamaño-grande peso-black p-0">
                                    Pedir
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>

                <div>
                    <Image
                        className="w-100"
                        src={imagen1}
                        alt="auto-banner-desktop"
                    />
                </div>
            </div>
            {/* Mobile  */}
            <div className="d-lg-none d-block banner-margen">
                <div className="d-flex justify-content-center">
                    <div className="d-sm-none d-block banner-logo">
                        <Image src={logo} alt="logo-banner" />
                    </div>
                    <div className=" banner-text">
                        <div className="d-none d-sm-block banner-logo">
                            <Image src={logo} alt="logo-banner" fluid />
                        </div>
                        <p className="tamaño-grande peso-black mb-0">
                            Date un gustito...
                        </p>
                        <p className="tamaño-chico">
                            Hacé tu pedido a través de Olaclick
                        </p>
                        <div>
                            <a
                                href="https://grillman.ola.click/products"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button className="boton-pedir color-fondo-naranja border-0 tamaño-grande peso-black p-0">
                                    Pedir
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="d-sm-none d-block">
                    <Image
                        className="banner-imagen"
                        src={imagen2}
                        alt="auto-banner-desktop"
                        fluid
                    />
                </div>
                <div className="d-none d-sm-block container">
                    <Image
                        className="banner-imagen"
                        src={imagen3}
                        alt="auto-banner-desktop"
                        fluid
                    />
                </div>
            </div>
        </div>
    );
}
