import { Image } from "react-bootstrap";
import logoFooter from "../assets/img/Logo.png";

export default function Footer() {
    return (
        <div className="mt-auto">
            <div className="d-flex justify-content-center redondeo-abajo">
                <Image src={logoFooter} fluid alt="logo" />
            </div>
            <div className="color-gris-fondo tamaño-chico peso-bold text-center text-md-start p-2  d-flex justify-content-center">
                <div>
                    <a
                        className="sin-sub text-white"
                        href="https://www.dkzstudio.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span >Produced by DKZ Studio &copy; {new Date().getFullYear()}</span>
                        <span > Todos los derechos reservados</span>
                    </a>
                </div>
            </div>
        </div>
    );
}
