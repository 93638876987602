import { Image } from "react-bootstrap";
import logoFooter from "../assets/img/Logo.png";
import email from "../assets/img/email.svg";
import { NavLink } from "react-router-dom";

export default function Sumate() {
    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-center mt-5">
                    <NavLink to={"/"}>
                        <Image
                            className="mt-4 pt-5"
                            src={logoFooter}
                            fluid
                            alt="logo"
                        />
                    </NavLink>
                </div>
                {/* escritorio */}
                <div className="d-none d-sm-block">
                    <div>
                        <div>
                            <p className=" tamaño-grande text-center text-white peso-black mt-5 mb-0">
                                ¡Sumate a nuestro equipo!
                            </p>
                            <p className="my-2 tamaño-medio peso-italic text-center">
                                Si tenés experiencia en parrillas o
                                sandwicherias y ganas de trabajar con nosotros
                                escribínos al siguiente correo:
                            </p>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center mt-5 pt-5">
                                <Image
                                    className="email-sumate-width my-2"
                                    src={email}
                                    fluid
                                    alt="logo"
                                />
                            </div>
                            <p className="tamaño-grande  text-center text-white peso-black mt-2 mb-5 pb-5">
                                {" "}
                                Info@grillman.com
                            </p>
                        </div>
                        <div className="pb-5">
                            <p className="text-center mt-5 mb-5 tamaño-medio peso-italic text-white-50  pb-5">
                                *En el asunto escribir CV + tu nombre completo
                            </p>
                        </div>
                    </div>
                </div>
                {/* Mobile*/}
                <div className="d-sm-none d-block ">
                    <div>
                        <div>
                            <p className="mx-4 tamaño-grande text-center text-white peso-black mt-5 mb-0">
                                ¡Sumate a nuestro equipo!
                            </p>
                            <p className="mx-4 px-2 my-2 tamaño-chico peso-italic">
                                Si tenés experiencia en parrillas o
                                sandwicherias y ganas de trabajar con
                                nosotros escribínos al siguiente correo:
                            </p>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center mt-5 pt-5">
                                <Image src={email} fluid alt="logo" />
                            </div>
                            <p className="tamaño-grande text-center text-white peso-black mt-2 mb-5 pb-5">
                                {" "}
                                Info@grillman.com
                            </p>
                        </div>
                        <div className="pb-5">
                            <p className="text-center mt-5 mb-5 tamaño-chico peso-italic text-white-50  pb-5">
                                *En el asunto escribir CV + tu nombre completo
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
