import facebook from "../assets/img/face.svg";
import instagram from "../assets/img/instagram.svg";
import { Image } from "react-bootstrap";

export default function RedesSociales() {
  return (
    <div className="container ">
      <hr className=" bg-white" />
      <div className="d-none d-sm-block text-center">
        <p className=" ms-4">
          ¡Síguenos en nuestras redes!{" "}
          <a className="sin-sub ms-4" href="https://www.facebook.com/GrillManTucuman">
            <Image src={facebook} fluid alt="icono" />{" "}
          </a>
          <a
            className="sin-sub"
            href="https://www.instagram.com/grillmantucuman/"
          >
            <Image src={instagram} fluid alt="icono" />
          </a>
        </p>
      </div>
      <div className="d-sm-none d-block">
        <p className="tamaño-chico ms-4">
          ¡Síguenos en nuestras redes!{" "}
          <a className="sin-sub ms-4" href="https://www.facebook.com/GrillManTucuman">
            <Image src={facebook} fluid alt="icono" />{" "}
          </a>
          <a
            className="sin-sub"
            href="https://www.instagram.com/grillmantucuman/"
          >
            <Image src={instagram} fluid alt="icono" />
          </a>
        </p>
      </div>
      <hr className=" bg-white" />
    </div>
  );
}
