import React from 'react'
import FormContacto from '../components/FormContacto'
import RedesSociales from '../components/RedesSociales'

export default function Franquicia() {
  return (
    <div>
      <FormContacto />
      <RedesSociales />
    </div>
  )
}
