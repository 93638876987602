import { Col, Form, InputGroup, Row, Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import logoFooter from "../assets/img/Logo.png";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import Swal from "sweetalert2";
import { useRef } from "react";
import { NavLink } from "react-router-dom";

export default function FormContacto() {
  const [validated, setValidated] = useState(false);
  const form = useRef();

  /*funcion para mandar msj al gmail*/
  const sendEmail = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === true) {
      e.stopPropagation();
      emailjs
        .sendForm(
          "service_rg18144",
          "template_httqo6d",
          e.target,
          "user_Stg5159fb3qxm0lYluqAN"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      Swal.fire({
        icon: "success",
        title: "Mensaje enviado con éxito",
        showConfirmButton: false,
        timer: 2000,
      });
      form.reset();
      setValidated(false); //
    } else {
      setValidated(true);
      Swal.fire({
        icon: "error",
        title: "No se pudo enviar el mensaje. Por favor verifica los datos.",
      });
    }
  };

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center mt-5 pt-5">
          <NavLink to={"/"}>
            <Image className="mt-4" src={logoFooter} fluid alt="logo" />
          </NavLink>
        </div>
        <div className="d-none d-sm-block">
          <p className=" tamaño-grande  text-center text-white peso-black mb-0">
            ¡Encendamos el fuego!
          </p>
          <p className="ms-4 my-2 text-white-50 tamaño-chico  text-center">
            Grillman es una empresa fundada por profesionales gastronómicos de
            excelente trayectoria. Actualmente la marca cuenta con un sólido
            posicionamiento, cinco puntos de venta y producción en Tucumán. La
            propuesta de franquicias nos permite crecer y expandirnos a lo largo
            de todo el país con un formato que favorece una óptima combinación
            entre rentabilidad, crecimiento y sostenibilidad del negocio,
            beneficiando tanto al franquiciado como a la marca, dado que la
            estructura se replica en cada nueva apertura.
          </p>
          <p className="tamaño-grande my-2  text-center text-white peso-black mb-0">
            Entérate de todo lo que podemos ofrecerte. Completa el siguiente
            formulario:
          </p>
        </div>
        <div className="d-sm-none d-block">
          <p className=" tamaño-grande  text-center text-white  peso-black mt-5 mb-0">
            ¡Encendamos el fuego!
          </p>
          <p className="ms-4 my-2 text-white-50 tamaño-chico  text-center">
            Grillman es una empresa fundada por profesionales gastronómicos de
            excelente trayectoria. Actualmente la marca cuenta con un sólido
            posicionamiento, cinco puntos de venta y producción en Tucumán. La
            propuesta de franquicias nos permite crecer y expandirnos a lo largo
            de todo el país con un formato que favorece una óptima combinación
            entre rentabilidad, crecimiento y sostenibilidad del negocio,
            beneficiando tanto al franquiciado como a la marca, dado que la
            estructura se replica en cada nueva apertura.
          </p>
          <p className="tamaño-grande  text-center text-white  peso-black mt-5 mb-0">
            Entérate de todo lo que podemos ofrecerte. <br /> Completa el
            siguiente formulario:
          </p>
        </div>

        <Form
          ref={form}
          noValidate
          validated={validated}
          onSubmit={sendEmail}
          className="mx-auto form-contactanos mb-5 p-2"
        >
          <div>
            <div className="card-body">
              <Row className="mb-2">
                <Form.Group
                  className="text-white"
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                >
                  <Form.Label className="text-white peso-italic tamaño-chico">
                    Nombre*
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    required
                    name="name"
                  />
                  <Form.Control.Feedback
                    className="peso-italic tamaño-chico"
                    type="invalid"
                  >
                    Ingrese su nombre completo por favor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="12" controlId="validationCustom04">
                  <Form.Label className=" text-white peso-italic tamaño-chico mt-2">
                    Whatsapp*
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    required
                    name="cel"
                  />
                  <Form.Control.Feedback
                    className="peso-italic tamaño-chico "
                    type="invalid"
                  >
                    Ingrese su número de WhatsApp por favor.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Label className="text-white peso-italic tamaño-chico mt-2">
                    Email*
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="email"
                    required
                    name="email"
                  />
                  <Form.Control.Feedback
                    className="peso-italic tamaño-chico "
                    type="invalid"
                  >
                    Ingrese su Email por favor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group
                  className="text-white"
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                >
                  <Form.Label className=" text-white peso-italic tamaño-chico">
                    Zona de interés*
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    required
                    name="zona"
                  />
                  <Form.Control.Feedback
                    className="peso-italic tamaño-chico"
                    type="invalid"
                  >
                    Ingrese su zona de interés por favor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} md="12" controlId="validationCustom04">
                  <Form.Label className=" text-white peso-italic tamaño-chico mt-2 ">
                    Mensaje*
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      className="mb-2"
                      as="textarea"
                      name="msj"
                      required
                    />
                    <Form.Control.Feedback
                      className="peso-italic tamaño-chico"
                      type="invalid"
                    >
                      Ingrese un mensaje por favor.
                    </Form.Control.Feedback>
                  </InputGroup>
                  <span className="mt-1 text-white peso-italic tamaño-chico">
                    * Campo requerido
                  </span>
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-center padding-fixed-contactanos  my-3 ">
            <Button
              className="boton-naranja-formulario border-0"
              size="sm"
              type="submit"
              value="Send"
            >
              <p className=" m-0 p-0  peso-black tamaño-medio">Enviar</p>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
