import {  Image } from "react-bootstrap";
import logoFooter from "../assets/img/Logo.png";
import { NavLink } from "react-router-dom";
import imagen2 from "../assets/img/imagen2.png";

export default function Conocenos() {
  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center mt-5">
          <NavLink to={"/"}>
            <Image className="mt-4 pt-5" src={logoFooter} fluid alt="logo" />
          </NavLink>
        </div>
        <div className="">
          <div>
            <div>
              <p className="mx-4 tamaño-grande text-center text-white peso-black my-4 pb-2">
                ¿Quienes somos?
              </p>
            </div>

            <div className="d-flex justify-content-center row">
              <div className="col-12 col-md-6">
                <Image
                  className="w-100 pb-sm-5 pb-0 px-sm-0 px-5"
                  src={imagen2}
                  alt="First slide"
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center">
                {" "}
                <p className="text-white tamaño-chico mb-0 px-5 celular-texto">
                  Somos una empresa familiar, reconocida por ofrecer productos
                  de gran calidad de elaboración, excelente sazón y donde la
                  carne al fuego es la principal protagonista. Desde el año
                  2017, en Grillman buscamos posicionarnos como la principal
                  opción para los amantes de la carne y los buenos sabores. En
                  cada sándwich vas a encontrar excelencia en los ingredientes y
                  vivir una experiencia única de sensaciones en tu boca.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
