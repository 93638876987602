import { Toast, Image, Collapse } from "react-bootstrap";
import face1 from "../assets/img/sinfotocomentario.jpg";
import face2 from "../assets/img/sinfotocomentario.jpg";
import face3 from "../assets/img/sinfotocomentario.jpg";
import face4 from "../assets/img/sinfotocomentario.jpg";
import estrellas from "../assets/img/stars.png";
import estrellas4 from "../assets/img/stars4.png";
import smile from "../assets/img/smile.svg";
import triangulo from "../assets/img/triangulocomentario.svg";

export default function Comentarios() {

    return (
        <>
            <div className="container">
                <div>
                    <div className="d-flex justify-content-center">
                        <div className="color-blanco-fondo comentario p-1 mx-2">
                            <div className="d-flex justify-content-around my-2 mx-2">
                                <p className="color-gris tamaño-medio peso-bold mb-0">
                                    Opiniones de nuestros <br /> comensales
                                </p>{" "}
                                <span>
                                    <Image
                                        className="ps-3"
                                        src={smile}
                                        fluid
                                        alt="comentario"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Image src={triangulo} fluid alt="triangulo" />
                </div>
                <div className=" container ">
                    <div className="ms-3 d-flex flex-wrap justify-content-between">
                        {/* Comentario #1 */}
                        <Toast className="col-6 fondo-card">
                            <Toast.Header className="fondo-card">
                                <div>
                                    <Image
                                        src={face1}
                                        style={{ width: "60px" }}
                                        roundedCircle
                                        fluid
                                        alt="perfil"
                                    />
                                </div>
                                <div className="ms-4 ms-sm-3 ">
                                    <span className="text-white">
                                        Luciana Andrea
                                    </span>{" "}
                                    <span className="ms-4 ms-sm-2 text-white-50">
                                        Sep/2022
                                    </span>{" "}
                                    <br />
                                    <div className="my-1">
                                        <span className="mt-2 text-white peso-bold">
                                            5
                                        </span>{" "}
                                        <span className="ms-2">
                                            <Image
                                                className="mb-1"
                                                src={estrellas}
                                                fluid
                                                alt="estrellas"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </Toast.Header>
                            <Toast.Body>
                                <p className="text-white-50">
                                    Excelente el punto de la carne, el sabor,
                                    todo exquisito... abundante la porción
                                </p>
                            </Toast.Body>
                        </Toast>
                        {/* Comentario #2 */}
                        <Toast className="col-6 fondo-card">
                            <Toast.Header className="fondo-card">
                                <div>
                                    <Image
                                        src={face2}
                                        style={{ width: "60px" }}
                                        roundedCircle
                                        fluid
                                        alt="perfil"
                                    />
                                </div>
                                <div className="ms-4 ms-sm-3">
                                    <span className="text-white">
                                        Romina Julieta
                                    </span>{" "}
                                    <span className="ms-4 ms-sm-2 text-white-50">
                                        Sep/2022
                                    </span>{" "}
                                    <br />
                                    <div className="my-1">
                                        <span className="mt-2 text-white peso-bold">
                                            4
                                        </span>{" "}
                                        <span className="ms-2">
                                            <Image
                                                className="mb-1"
                                                src={estrellas4}
                                                fluid
                                                alt="estrellas"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </Toast.Header>
                            <Toast.Body>
                                <p className="text-white-50">
                                    El sanguche de morcilla excelente, pero
                                    debería venir pisada o por lo menos cortada
                                    al medio
                                </p>
                            </Toast.Body>
                        </Toast>
                        {/* Comentario #3 */}
                        <Toast className="col-6 fondo-card">
                            <Toast.Header className="fondo-card">
                                <div>
                                    <Image
                                        src={face3}
                                        style={{ width: "60px" }}
                                        roundedCircle
                                        alt="perfil"
                                        fluid
                                    />
                                </div>
                                <div className="ms-4 ms-sm-3">
                                    <span className="text-white">Emanuel</span>{" "}
                                    <span className="ms-4 ms-sm-2 text-white-50">
                                        Sep/2022
                                    </span>{" "}
                                    <br />
                                    <div className="my-1">
                                        <span className="mt-2 text-white peso-bold">
                                            5
                                        </span>{" "}
                                        <span className="ms-2">
                                            <Image
                                                className="mb-1"
                                                src={estrellas}
                                                fluid
                                                alt="estrellas"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </Toast.Header>
                            <Toast.Body>
                                <p className="text-white-50">
                                    Exquistos como siempre los sandwiches, lo
                                    único que nunca le ponen suficiente picante
                                    como pido, aunque es por que soy exagerado.
                                </p>
                            </Toast.Body>
                        </Toast>
                        {/* Comentario #4 */}
                        <Toast className="col-6 fondo-card">
                            <Toast.Header className="fondo-card">
                                <div>
                                    <Image
                                        src={face4}
                                        style={{ width: "60px" }}
                                        roundedCircle
                                        fluid
                                        alt="perfil"
                                    />
                                </div>
                                <div className="ms-4 ms-sm-3">
                                    <span className="text-white">Yani</span>{" "}
                                    <span className="ms-4 ms-sm-2 text-white-50">
                                        Jul/2022
                                    </span>{" "}
                                    <br />
                                    <div className="my-1">
                                        <span className="mt-2 text-white peso-bold">
                                            4
                                        </span>{" "}
                                        <span className="ms-2">
                                            <Image
                                                className="mb-1"
                                                src={estrellas4}
                                                fluid
                                                alt="estrellas"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </Toast.Header>
                            <Toast.Body>
                                <p className="text-white-50">
                                    Como siempre, exquisito todo!!! ❣
                                </p>
                            </Toast.Body>
                        </Toast>
                    </div>
                </div>
            </div>
        </>
    );
}
