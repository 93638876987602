import React from "react";
import CardsTentate from "./CardsTentate";
import Descuento from "./Descuento";

export default function Tentate() {
    return (
        <div>
            {/* Escritorio   */}
            <div className="d-none d-sm-block container">
                <div className="color-fondo-naranja fondo-escritorio-tentante">
                    <div className="redondeo-abajo-confondo-escritorio ">
                        <div className="mx-5 pb-4 pt-5">
                            <p className="peso-black tamaño-medio my-4 pb-3">
                                Imagina entre tus manos un sándwich hecho con el
                                pan más suave, y la carne más jugosa recién
                                salida de la parrilla. No te resistas… ya
                                prendimos el fuego
                            </p>
                            <p className="peso-black tamaño-grande mb-0">
                                Tentate
                            </p>
                            <p className="interlineado tamaño-chico mb-0">
                                Descrubrí lo mejor de la carne al fuego con
                                Grillman y sus años de experiencia frente la
                                parrilla
                            </p>
                        </div>
                        <CardsTentate />
                    </div>
                </div>
                <Descuento />
            </div>
            {/* mobile  */}
            <div className="d-sm-none d-block">
                <div className="color-fondo-naranja">
                    <div className="redondeo-abajo-confondo">
                        <div className="mx-5 pb-4 pt-5">
                            <p className="peso-black tamaño-medio my-4 pb-3">
                                Imagina entre tus manos un sándwich hecho con el
                                pan más suave, y la carne más jugosa recién
                                salida de la parrilla. No te resistas… ya
                                prendimos el fuego
                            </p>
                            <p className="peso-black tamaño-grande mb-0">
                                Tentate
                            </p>
                            <p className="interlineado tamaño-chico mb-0">
                                Descrubrí lo mejor de la carne al fuego con
                                Grillman y sus años de experiencia frente la
                                parrilla
                            </p>
                        </div>
                        <CardsTentate />
                    </div>
                </div>
                <Descuento />
            </div>
        </div>
    );
}
