import { Image } from "react-bootstrap";
import foodtruck1 from "../assets/img/local1.png";
import foodtruck2 from "../assets/img/local2.png";

export default function Locales() {
    return (
        <div className="container my-5">
            <div className="text-center">
                {/* escritorio */}
                <div className="d-none d-lg-flex">
                    <div className="my-4 w-50 me-4">
                        <a href="https://api.whatsapp.com/send/?phone=543814665263&text&type=phone_number&app_absent=0">
                            <Image
                                className="w-100"
                                src={foodtruck1}
                                fluid
                                alt="icono"
                            />
                        </a>
                        <p className="peso-bold tamaño-medio text-start">
                            · Centro - Suipacha 210
                        </p>
                    </div>
                    <div className="my-4 w-50 ms-4">
                        <a href="https://api.whatsapp.com/send/?phone=543816222558&text&type=phone_number&app_absent=0">
                            <Image
                                className="w-100 "
                                src={foodtruck2}
                                fluid
                                alt="icono"
                            />
                        </a>
                        <p className="peso-bold tamaño-medio text-start">
                            · Lomas - Av. Raya y Suter
                        </p>
                    </div>
                </div>
                {/* mobile */}
                <div className="d-lg-none d-block">
                    <div className="my-4">
                        <a href="https://api.whatsapp.com/send/?phone=543814665263&text&type=phone_number&app_absent=0">
                            <Image
                                className="w-100"
                                src={foodtruck1}
                                fluid
                                alt="icono"
                            />
                        </a>
                        <p className="peso-bold tamaño-medio text-start">
                            · Centro - Suipacha 210
                        </p>
                    </div>
                    <div className="my-4">
                        <a href="https://api.whatsapp.com/send/?phone=543816222558&text&type=phone_number&app_absent=0">
                            <Image
                                className="w-100"
                                src={foodtruck2}
                                fluid
                                alt="icono"
                            />
                        </a>
                        <p className="peso-bold tamaño-medio text-start">
                            · Lomas - Av. Raya y Suter
                        </p>
                    </div>
                </div>
            </div>
            <hr className="bg-white mt-5" />
        </div>
    );
}
