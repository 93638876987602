import { Image } from "react-bootstrap";
import triangulo from "../assets/img/triangulo.svg";

export default function Descuento() {
  return (
    <div>
      <div className="color-fondo-naranja text-center py-4 redondeo-abajo">
        <p className="peso-bold color-naranja-oscuro  mb-2">
          ¿ya te decidiste?
        </p>
        <p className="tamaño-grande peso-black mb-0 interlineado2">
          APROVECHÁ nuestro
          <br />
          ¡TAKE AWAY!
        </p>
        <p className="tamaño-gigante peso-black color-naranja-oscuro margen-nega">10% OFF</p>
        <p className="tamaño-chico peso-bold color-naranja-oscuro interlineado">
          Comprando para llevar en nuestros locales!
        </p>
      </div>
      <div className="d-flex justify-content-center"> <Image src={triangulo} fluid alt="icono" /></div>
    </div>
  );
}
